export interface AccessMap {
  dev: string[];
  qa: string[];
  production: string[];
}

export const shortFormMap: { [key: string]: string } = {
  step: 'Self-service Training Efficiency Profile',
  tap: 'Training Analytics Performance',
  allocator: 'Alloc8or',
};