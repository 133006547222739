import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ParentRootLayout } from "../pages/parentRootLayout";
import { CustomErrorPage } from '../pages/CustomErrorPage';
const RouterOutlet = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ParentRootLayout />} />
        <Route index element={<ParentRootLayout />} />
        <Route path="/error" element={<CustomErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterOutlet;