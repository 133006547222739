import { configureStore, ThunkAction, Action, createListenerMiddleware } from '@reduxjs/toolkit';
import applicationsReducer, { applicationsActions } from './slices/applicationSlice';
import { AddListenerOverloads, UnsubscribeListener } from '@reduxjs/toolkit/dist/listenerMiddleware/types';

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    apps: applicationsReducer,
  },
  middleware: getDefaultMiddlewares => getDefaultMiddlewares().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppListener = AddListenerOverloads<UnsubscribeListener, RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppListener;

startAppListening({
  actionCreator: applicationsActions.handleRecents,
  effect: (_, { dispatch }) => {
    dispatch(applicationsActions.postRecents());
    dispatch(applicationsActions.filterApplications());
  },
});

startAppListening({
  actionCreator: applicationsActions.updateSearchQuery,
  effect: (_, { dispatch }) => {
    dispatch(applicationsActions.filterApplications());
  },
});
