import { Box, Button } from '@mui/material';
import { FC } from 'react';
import DTH_LOGO from '../../assets/images/DTH_LOGO.png';
import Info from '../../app/assets/images/Info.svg';
import Reverse from '../../app/assets/images/Reverse.svg';
import { labels, numbers } from '../constants/dthConstants';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { useAppSelector } from '../hooks';

interface NoAccess {
  header?: string;
  body?: string;
  buttonText?: string;
  isButtonVisible?: boolean;
  clickHandler?: () => void;
}
export const CustomErrorPage: FC<NoAccess> = ({
  header = labels.errorHeader,
  body = labels.errorBody,
  buttonText = labels.errorButtonText,
  isButtonVisible = true,
  clickHandler,
}) => {
  const clickedApp = useAppSelector(state => state.apps.applicationClicked).toUpperCase();
  const navigateToDTH = () => {
    // window.open(process.env.REACT_APP_REDIRECT_URI);
    const redirectUri: Location = process.env.REACT_APP_REDIRECT_URI as unknown as Location;
    window.location = redirectUri;
  };
  return (
    <div className="h-screen w-screen flex flex-col">
      <Header />
      <div className="flex flex-grow justify-center items-center">
        <Box
          sx={{
            position: 'fixed',
            top: '110px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: -numbers.THOUSAND,
          }}
        >
          <img
            src={DTH_LOGO}
            alt="DTH Logo water mark"
            style={{ height: '404px', width: '558px', zIndex: -numbers.TEN }}
          />
        </Box>
        <div className="flex flex-col  items-center ">
          <img src={Info} alt="Info Icon" />
          <div className="text-[40px] leading-[50px] text-justify font-bold font-jnjDBold">{header}</div>
          <div className="text-[15px] pt-3 leading-[50px] text-justify font-jnjTRegular">{body + clickedApp}</div>
          {isButtonVisible && (
            <div className="px-[10%] pt-7">
              <Button className="rounded-lg" variant="contained" onClick={navigateToDTH}>
                {buttonText}
                <img src={Reverse} alt="Reverse Icon" className="pl-1" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
