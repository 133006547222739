import React, { useState, useEffect } from "react";
import { getAuthToken } from "../authentication/authToken";
import { UserDetail } from "./userDetail";
import Avatar from "@mui/material/Avatar";
import { logError } from "../utils/commonMethods";
import axiosIntercept from "../authentication/axiosInterceptor";

/**
 * UserProfileImage.
 * This component using Avatar component to show image
 * This component using UserDetail component to get user data
 * @returns {JSX.Element}
 */
export const UserProfileImage = () => {
  let token = getAuthToken();
  const [initializer, setInitializer] = useState(true);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (initializer && token?.graphToken) {
      axiosIntercept
        .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
          responseType: 'blob',
          headers: {
            Authorization: token.graphToken,
          },
        })
        .then((response: any) => {
          let imgUrl = URL.createObjectURL(response.data);
          setImageURL(imgUrl);
        })
        .catch((error: any) => {
          logError('Profile photo not found for the logged in user. ', error);
        });
      setInitializer(false);
    }
  }, [initializer, token]);

  return (
    <>
      <Avatar alt="Profile Photo" src={imageURL}>
        {UserDetail().userInitials}
      </Avatar>
    </>
  );
};
