import { getAuthToken } from "../authentication/authToken";

export const UserDetail = () => {

    let token = getAuthToken();
    let userFirstName = token.userName.substring(token.userName.indexOf(',') + 1, token.userName.indexOf('[')).trim();
    let userLastName = token.userName.substring(0, token.userName.indexOf(',')).trim();
    let userInitials = (userFirstName.substring(0, 1) + userLastName.substring(0, 1)).toUpperCase();

    return {
        userEmailId: token.userEmailId,
        userName: token.userName,
        userFirstName: userFirstName,
        userLastName: userLastName,
        userInitials: userInitials,
        userRoles: token.roles as string[]
    };
}
