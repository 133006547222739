import { FC, PropsWithChildren } from 'react';

interface BackdropProps {
    className?: string;
    handleClick?: () => void;
}

const Backdrop: FC<PropsWithChildren<BackdropProps>> = ({children, className, handleClick}) => {
  return (
    <div className={`${className} fixed top-0 left-0 z-[2000] w-full h-screen bg-[rgba(0,0,0,0.3)]`} onClick={handleClick}>{children}</div>
  )
}

export default Backdrop