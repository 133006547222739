// Saving all the project level constants here
export const LINK_TO_TAP = process.env.REACT_APP_REDIRECT_URI_TAP;
export const LINK_TO_STEP = process.env.REACT_APP_REDIRECT_URI_STEP;
export const LINK_TO_ALLOC8OR = process.env.REACT_APP_REDIRECT_URI_ALLOC8OR;
export const DTH_AUTH_DETAILS = 'DTH_Auth_Details';
export const UNAUTHORIZED_MESSAGE =
  'Note: You do not have the necessary rights to access Alloc8or as it is only intended for R&D Quality Administrators.';
export const OK = 'Ok';
export const labels = {
  errorHeader: 'No Access!',
  errorBody: "You don't have access to ",
  errorButtonText: 'Go Back',
};
export const numbers = {
  ZERO: 0,
  ONE: 1,
  TEN: 10,
  SIXTY: 60,
  THOUSAND: 1000,
  FOURHUNDREDONE: 401,
  FOURHUNDREDTHREE: 403
};

export const allocatorRoleArray = {
  dev: ['ITS-DTH-ALLOC8ORADMIN-DEV'],
  qa: ['ITS-DTH-ALLOC8ORADMIN-QA'],
  production: ['ITS-DTH-ALLOC8ORADMIN'],
};

export const tapRoleArray = {
  dev: ['ITS-APP-DTH-Azure-TAPAdmin-DEV'],
  qa: ['ITS-APP-DTH-Azure-TAPAdmin-QA'],
  production: ['ITS-APP-DTH-Azure-TAPAdmin'],
};

export const stepRoleArray = {
  dev: [`ITS-DTH-STEPADMIN-DEV`, `ITS-DTH-STEPLMSADMIN-DEV`, `ITS-DTH-STEPCWUSER-DEV`, `ITS-DTH-STEPCWMANAGER-DEV`],
  qa: [`ITS-DTH-STEPADMIN-QA`, `ITS-DTH-STEPLMSADMIN-QA`, `ITS-DTH-STEPCWUSER-QA`, `ITS-DTH-STEPCWMANAGER-QA`],
  production: [`ITS-DTH-STEPADMIN`, `ITS-DTH-STEPLMSADMIN`, `ITS-DTH-STEPCWUSER`, `ITS-DTH-STEPCWMANAGER`],
};
