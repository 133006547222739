import axios, { AxiosHeaders } from 'axios';
import { getAuthToken } from './authToken';
import { numbers } from '../constants/dthConstants';

export interface RequestConfig {
  headers?: Record<string, string>;
  params?: Record<string, unknown>;
  responseType?: 'json' | 'blob';
  baseUrl?: string;
}

const axiosIntercept = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosIntercept.interceptors.request.use(
  config => {
    const token = getAuthToken();
    /// Setting token in header
    return {
      ...config,
      headers: AxiosHeaders.from({
        ...(token.idToken !== null ? { Authorization: `Bearer ${token.idToken}` } : {}),
        ...config.headers,
      }),
    };
  },
  error => {
    return Promise.reject(error);
  }
);

axiosIntercept.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === numbers.FOURHUNDREDONE || error.response.status === numbers.FOURHUNDREDTHREE) {
      // Session expired
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
    }
    return Promise.reject(error);
  }
);

export async function get<R>(url: string, config?: RequestConfig) {
  return axiosIntercept.get<R>(url, {
    headers: AxiosHeaders.from({
      ...(config?.headers || {}),
    }),
    params: config?.params || {},
    responseType: config?.responseType || 'json',
    baseURL: config?.baseUrl ?? axiosIntercept.defaults.baseURL,
  });
}

export async function post<R = unknown, P = unknown>(
  url: string,
  payload: P,
  config: RequestConfig = { headers: {}, params: {}, responseType: 'json', baseUrl: '' }
) {
  return axiosIntercept.post<R>(url, payload, {
    headers: AxiosHeaders.from({
      ...config.headers,
    }),
    params: config.params,
    responseType: config.responseType,
    baseURL: config.baseUrl || axiosIntercept.defaults.baseURL,
  });
}

export async function put<R = unknown, P = unknown>(
  url: string,
  payload: P,
  config: RequestConfig = { headers: {}, params: {}, responseType: 'json', baseUrl: '' }
) {
  return axiosIntercept.put<R>(url, payload, {
    headers: AxiosHeaders.from({
      ...config.headers,
    }),
    params: config.params,
    responseType: config.responseType,
    baseURL: config.baseUrl || axiosIntercept.defaults.baseURL,
  });
}

export async function del<R>(
  url: string,
  config: RequestConfig = { headers: {}, params: {}, responseType: 'json', baseUrl: '' }
) {
  return axiosIntercept.delete<R>(url, {
    headers: AxiosHeaders.from({
      ...config.headers,
    }),
    data: config.params?.wttExceptionChecked,
    responseType: config.responseType,
    baseURL: config.baseUrl || axiosIntercept.defaults.baseURL,
  });
}

export default axiosIntercept;
