import React, { FC } from 'react';
import ApplicationCard, { ApplicationCardProps } from './ApplicationCard';
import { Application } from '../types/response';

interface ApplicationListProps {
  title?: string;
  items: Application[];
  cardProps?: Omit<ApplicationCardProps, 'app' | 'disablePopup'>;
  containerClass?: string;
  titleClass?: string;
  itemsContainerClass?: string;
  disablePopup?: boolean;
  gridColumns?: string; 
}

const ApplicationList: FC<ApplicationListProps> = ({
  title,
  items = [],
  cardProps = {},
  containerClass = '',
  titleClass = '',
  itemsContainerClass = '',
  disablePopup = false,
  gridColumns = 'grid-cols-5',
}) => {
  // Inline CSS for hiding elements below 1024px
  const styles = `
    @media (max-width: 1024px) {
      .hidden-below-lg {
        display: none !important;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className={`space-y-6 ${containerClass}`}>
        {title && <h3 className={`text-2xl font-medium font-jnjDBold ${titleClass}`}>{title}</h3>}
        <div className={`grid ${gridColumns} grid-rows-[auto] gap-y-6 ${itemsContainerClass}`}>
          {items.map(item => (
            <ApplicationCard 
              key={item?.label} 
              app={item} 
              disablePopup={disablePopup} 
              {...cardProps} 
              containerClass={ item.key === 'alloc8or' ? 'hidden-below-lg' : ''}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ApplicationList;
