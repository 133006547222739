import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/style.css';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './app/authentication/authConfig';
import { ModalContextProvider } from './app/context/ModalContext';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const container = document.getElementById('root')!;
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: '#EB1700',
    },
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: container,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: container,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: container,
      },
    },
    MuiModal: {
      defaultProps: {
        container: container,
      },
    },
  },
});

const cache = createCache({
  key: 'css',
  prepend: true,
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <StyledEngineProvider injectFirst>
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <ModalContextProvider>
                <App />
              </ModalContextProvider>
            </ThemeProvider>
          </CacheProvider>
        </StyledEngineProvider>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();