
import { useSelector } from 'react-redux';
import { BlueTheme, RedTheme } from './theme';
/**
* SelectedTheme.
* @returns {object}
*/
export const SelectedTheme = () => {
    const theme = [RedTheme, BlueTheme];
    let getThemeFromState = useSelector((state: any) => {
        return state.themeSwitcherRd;
    });
    const themeSelected =
        getThemeFromState && getThemeFromState.selectedTheme?.themeType
            ? theme[getThemeFromState.selectedTheme.themeType]
            : theme[0];

    return themeSelected

}