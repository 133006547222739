import { FC, Fragment, PropsWithChildren } from 'react'
import Backdrop from './Backdrop';
import logo from '../assets/images/Close_ICON.svg';
import { IconButton } from '@mui/material';

interface ModalProps {
    showCloseIcon?: boolean;
    handleClose?: () => void;
    showBackdrop?: boolean;
    backdropClass?: string;
    modalWrapperClass?: string;
    handleBackdropClick?: () => void
}

const ModalWrapper: FC<PropsWithChildren<ModalProps>> = ({children, showBackdrop, backdropClass, modalWrapperClass, showCloseIcon = true, handleClose, handleBackdropClick}) => {
  return (
    <Fragment>
        {showBackdrop && <Backdrop className={backdropClass || ''} handleClick={handleBackdropClick} />}
        <div className={`fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-[2500] -:w-fit -:bg-white -:rounded-2xl ${modalWrapperClass || ''}`}>
          {showCloseIcon && <div className='flex justify-end'><IconButton onClick={() => handleClose?.()}><img src={logo} alt="close icon" className='h-4 w-4' /></IconButton></div>}
            {children}
        </div>
    </Fragment>
  )
}

export default ModalWrapper