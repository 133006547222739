import { FC, PropsWithChildren, Children, cloneElement, useEffect, useRef, useState } from 'react';

interface ScrollProps {
  className?: string;
}

const Scroll: FC<PropsWithChildren<ScrollProps>> = ({ children, className = '' }) => {
  const [hideScroll, setHideScroll] = useState(true);
  const childRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    childRef.current.forEach(element => {
      element.addEventListener('scroll', () => setHideScroll(false));
      element.addEventListener('scrollend', () => setHideScroll(true));
    });
  }, []);
  return (
    <div className={`scroll ${className}`}>
      <span className={`${hideScroll ? 'hideScroll' : ''}`}></span>
      {children &&
        Children.map(children, (child, index) =>
          cloneElement(child as JSX.Element, { ref: (ref: HTMLElement) => (childRef.current[index] = ref) })
        )}
    </div>
  );
};

export default Scroll;
