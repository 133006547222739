

import { Box } from '@mui/material';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import IntroductionBanner from '../components/IntroductionBanner';
import Applications from '../components/Applications';
import { RedTheme } from '../global/theme';

export const ParentRootLayout = () => {
  const redtheme = RedTheme;
  return (
    <>
      <Box component="main" className="flex-grow flex flex-col" sx={{ flex: '1 0 auto' }}>
        {/** 12 Colum Grid defined for base layout */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: 1,
            gridTemplateRows: 'auto',
            gridTemplateAreas: `"main main main main main main main main main main main main"`,
            flexGrow: 1,
          }}
        >
          <Box className="tap-page-layout relative flex flex-col sm:grid sm:grid-cols-10 sm:grid-rows-1" sx={{ gridArea: 'main', background: redtheme.palette.primary.dark }}>
            {/** Header */}
            <Header></Header>
            {/* <Box
                        className='tap-content-area'
                        sx={{
                            padding: '0 72px 10px 72px',
                            width: '100%',
                        }}
                        >
                        Content area loads
                        <Box
                            className='dashboard'
                            sx={{
                            p: '22px 0',
                            }}
                        >
                            <Outlet />
                            <LandingPage />
                        </Box>
                        </Box> */}
            <div className="col-span-10 sm:col-span-3 flex flex-col h-[fit-content] sm:h-auto">
              <IntroductionBanner />
            </div>
            <div className="col-span-7 flex flex-col">
              <Applications />
            </div>
          </Box>
        </Box>
      </Box>
      {/** Footer */}
      <Box component="footer" sx={{ flexShrink: 0 }}>
        <Footer></Footer>
      </Box>
    </>
  );
};
