import { FC, PropsWithChildren, ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";
import ModalWrapper from "../atoms/ModalWrapper";
import { numbers } from "../constants/dthConstants";

export interface IModalContext {
    initiate: (component: ReactNode, config?: Partial<ModalState['config']>) => void;
    close: () => void;
}

export interface ModalState {
    open: boolean,
    component: ReactNode,
    timer: NodeJS.Timeout | null,
    config: {
        showCloseIcon: boolean,
        modalWrapperClass: string,
        showBackdrop: boolean,
        backdropClass: string,
        closeOnBackdropClick: boolean,
        autoClose: boolean;
        autoCloseTime: number;
    }
}

export const ModalContext = createContext<IModalContext | null>(null);

const initialState: ModalState = {
    open: false,
    component: null,
    timer: null,
    config: {
        showCloseIcon: true,
        modalWrapperClass: '',
        showBackdrop: true,
        backdropClass: '',
        closeOnBackdropClick: false,
        autoClose: false,
        autoCloseTime: numbers.THOUSAND
    }
}

export const ModalContextProvider: FC<PropsWithChildren> = ({children}) => {
    const [state, setState] = useState<ModalState>(initialState);

    const startAutoCloseTimer = useCallback((config: Partial<ModalState['config']>) => {
        state.timer && clearTimeout(state.timer);
        if(config.autoClose) return setTimeout(() => setState(initialState), config.autoCloseTime || numbers.THOUSAND);
        return null;
    }, []);

    const value = useMemo<IModalContext>(() => ({
        initiate: (component, config?) => {
            const newConfig = {...state.config, ...(config || {})}
            setState(prev => ({...prev, open: true, component, timer: startAutoCloseTimer(newConfig), config: newConfig }))
        },
        close: () => setState(initialState)
    }), [])

    return <ModalContext.Provider value={value}>
        {state.open && <ModalWrapper 
            showCloseIcon={state.config.showCloseIcon}
            handleClose={value.close}
            showBackdrop={state.config.showBackdrop} 
            backdropClass={state.config.backdropClass} 
            modalWrapperClass={state.config.modalWrapperClass} 
            handleBackdropClick={() => state.config.closeOnBackdropClick && value.close()}
        >
            {state.component}
        </ModalWrapper>}
        {children}
        </ModalContext.Provider>
}

export const useModal = () => {
    const modalContext = useContext(ModalContext);

    if(!modalContext) throw new Error("Called Outside ModalContext");

    return modalContext;
}