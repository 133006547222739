import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
/**
* LogoutView.
* This component use to show after signout screen
* @returns {JSX.Element}
*/
export const LogoutView = () => {
  
  useEffect(() => {
    Swal.fire({
      html: "<h4 style='padding-top: 4em; padding-bottom: 4em'>You have signed out of your account.</h4>",
      showConfirmButton: true,
      confirmButtonText: "Login",
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
    }).then(result => {
      if (result.isConfirmed) {

        window.location.href = process.env.REACT_APP_REDIRECT_URI;
      }
    });    
  });

  return <div></div>;
};
