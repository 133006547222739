import { UserDetail } from "../components/userDetail";
import _ from 'lodash';
import { allocatorRoleArray, stepRoleArray, tapRoleArray } from '../constants/dthConstants';
import { AccessMap } from '../types/common';

const appAccessMap: Record<string, AccessMap> = {
  alloc8or: allocatorRoleArray,
  step: stepRoleArray,
  tap: tapRoleArray,
};

/*
@returns - boolean
This function checks environment specific user access for DTH Apps in case the App is role restricted
*/
export const checkForAppAccess = (appName: string) => {
  if (appName !== 'alloc8or') return true;
  const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase() as keyof AccessMap;
  const roles = UserDetail().userRoles || [];
  if (!(appName in appAccessMap)) return true;
  const userRoleMap = appAccessMap[appName];
  return _.intersection(roles, userRoleMap[environment as keyof typeof stepRoleArray]).length > 0;
};

// export const checkForAlloc8orAccess = () => {
//   const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
//   const roles = UserDetail().userRoles || [];
//   return roles.includes(alloc8orRoleToEnvMap[environment as keyof typeof alloc8orRoleToEnvMap]);
// };

// export const checkForTAPAccess = () => {
//   const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
//   const roles = UserDetail().userRoles || [];
//   return roles.includes(tapRoleToEnvMap[environment as keyof typeof alloc8orRoleToEnvMap]);
// };

// export const checkForSTEPAccess = () => {
//   const environment = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
//   const roles = UserDetail().userRoles || [];
//   return _.intersection(roles, stepRoleToEnvMap[environment as keyof typeof stepRoleToEnvMap]).length > 0;
// };

// export const isSiteAccessable = (clickedApp: string) => {
//   // if (clickedApp === 'Self-service Training Efficiency Profile') return checkForSTEPAccess();
//   if (clickedApp === 'Alloc8or') return checkForAlloc8orAccess();
//   // else if (clickedApp === 'Training Analytics Performance') return checkForTAPAccess();
//   else return true;
// };

export const createAppUrl = (url: string) => process.env[url];