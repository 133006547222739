/**
* msalConfig.
* cacheLocation - This configures where your cache will be stored
* storeAuthStateInCookie - Set this to "true" if you are having issues on IE11 or Edge
* authority - This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
* Login Request.
* Add scopes here for ID token to be used at Microsoft identity platform endpoints.
* Graph Config
* Add the endpoints here for Microsoft Graph API services you'd like to use.
*/
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID || '',
    authority: process.env.REACT_APP_AUTHORITY_URL_WITH_TENANT_ID, 
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI + '/logout',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, 
  },
};

/**
* Login Request.
* Add scopes here for ID token to be used at Microsoft identity platform endpoints.
*/
export const loginRequest = {
  scopes: ['User.Read'],
};

/**
* Graph Config
* Add the endpoints here for Microsoft Graph API services you'd like to use.
*/
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
