import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { applicationsActions } from '../redux/slices/applicationSlice';

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import ApplicationList from './ApplicationList';
import Scroll from '../atoms/Scroll';
import { Box } from '@mui/material';

const Applications: FC = () => {
  const dispatch = useAppDispatch();
  const allApps = useAppSelector(state => state.apps.filteredApps);
  const recents = useAppSelector(state => state.apps.filteredRecents);

  useEffect(() => {
    dispatch(applicationsActions.fetchApplications());
  }, []);

  const showRecents = !!recents.length;

  return (
    <div className="pl-6 sm:pt-[94px] pt-[30px] flex flex-col flex-grow space-y-4">
      <FormControl variant="outlined" className="w-1/2 sm:min-w-1/2 min-w-[240px] bg-white">
        <OutlinedInput
          placeholder="Search for Applications"
          className="rounded-lg"
          inputProps={{
            className: 'p-2 text-base/6 font-normal font-jnjTRegular',
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={event => dispatch(applicationsActions.updateSearchQuery(event.target.value))}
        />
      </FormControl>
      <Scroll className="flex flex-col flex-grow">
        <div className="overflow-auto flex-grow md:space-y-1 sm:space-y-14 h-full sm:h-auto sm:max-h-[calc(100vh-200px)] pb-[100px] md:max-h-full">
          {showRecents &&
            <ApplicationList
              title="Recent Apps"
              items={recents}
              disablePopup
              gridColumns="lg:grid-cols-5 grid-cols-3 lg:gap-x-8 pl-10"
              cardProps={{
                imageOverlayClass: 'sm:h-[124px] sm:w-[124px] h-[92px] w-[92px] xl:w-[156px] xl:h-[156px]',
                imageClass: 'sm:h-[80px] sm:w-[86.62px] h-[64px] w-[67.55px]'
              }}
            />}
          <Box className="space-y-6">
            <ApplicationList
              title="All Apps"
              items={allApps}
              gridColumns="xl:grid-cols-5 grid-cols-3"
              cardProps={{
                imageOverlayClass: 'p-6 w-[92px] h-[92px] sm:h-[124px] sm:w-[124px] xl:w-[108px] xl:h-[108px] 2xl:w-[156px] 2xl:h-[156px]',
                imageClass: 'h-[108px] w-[108px] h-[64px] w-[67.55px] sm:h-[80px] sm:w-[86.62px] xl:w-[69.3px] xl:h-[64px]  2xl:w-[95.3px] 2xl:h-[88px] '
              }}
            />
          </Box>
        </div>
      </Scroll>
    </div>
  );
};

export default Applications;
