import React, { useEffect } from "react";
import "./App.css";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./app/authentication/authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LogoutView } from "./app/pages/logoutView";
import { BlueTheme, RedTheme } from "./app/global/theme";
import { useSelector } from "react-redux";
import RouterOutlet from "./app/navigation/routerConfig";

declare global {
  interface Window {
    msalInstance: any;
    msalUserName: any;
  }
}
function App() {
  const theme = [RedTheme, BlueTheme];
  let setThemeInState = useSelector((state: any) => {
    return state.themeSwitcherRd;
  });
  const themeSelected = setThemeInState?.selectedTheme?.themeType
    ? theme[setThemeInState.selectedTheme.themeType]
    : theme[0];
  let { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isLogout = window.location && (window.location.pathname || '').toLowerCase().includes('logout');
  const AUTHENTICATION_TIMEOUT = 500;
  const isDev = process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'dev';
  const renewalTime = {
    five: 5,
    sixty: 60,
    thousand: 1000,
  };
  const TOKEN_RENEWAL_TIME = renewalTime.five * renewalTime.sixty * renewalTime.thousand;
  const LOGIN_TIMEOUT = 1500;

  useEffect(() => {
    document.title =
      process.env.REACT_APP_ENVIRONMENT === 'production' ? 'DTH' : 'DTH - ' + process.env.REACT_APP_ENVIRONMENT;
    // detecting logout from use-cases
    if (window.location && (window.location.search || '').toLowerCase().includes('logout=true')) {
      //way to logout via query string
      instance.logout();
    }
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (!isAuthenticated) {
      if (isLogout) {
        //does not execute login redirect
      } else {
        setTimeout(() => {
          if (instance.getAllAccounts().length === 0) {
            instance
              .handleRedirectPromise()
              .then(_response => {
                console.log('response', _response); //NOSONAR
              })
              .catch(err => {
                console.log('handle Redirect Promise not found', err); //NOSONAR
              });

            if (!localStorage['redirectURI']) {
              localStorage['redirectURI'] = window.location.pathname;
            }
            instance.loginRedirect(loginRequest).catch(e => {
              console.log('login Redirect error found', e); //NOSONAR
            });
          }
        }, AUTHENTICATION_TIMEOUT);
      }
    } else {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      const userName = instance.getAllAccounts()[0].username;
      window.msalInstance = instance;
      window.msalUserName = userName;
      if (localStorage["redirectURI"] && localStorage["redirectURI"] !== "") {
        let redirectURI = localStorage["redirectURI"];
        localStorage.removeItem("redirectURI");
        window.location.pathname = redirectURI;
      }

      // Set auto token renewal call on each 5 min
      interval = setInterval(() => {
        let config = {
          scopes: loginRequest.scopes,
          authority: msalConfig.auth.authority,
          account: instance.getAllAccounts()[0],
        };
        let redRequest = {
          scopes: loginRequest.scopes,
          loginHint: userName,
        };
        instance
          .acquireTokenSilent(config)
          .then(
            res => {
              //logError('Token Renewed...',isDev ? res : {})
              console.log("Token Renewed...", isDev ? res : {}); //NOSONAR
            },
            err => {
              if (err instanceof InteractionRequiredAuthError) {
                return instance.acquireTokenRedirect(redRequest);
              }
            }
          )
          .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
              return instance.acquireTokenRedirect(redRequest);
            }
          });
        return () => clearInterval(interval);
      }, TOKEN_RENEWAL_TIME);
      setTimeout(() => {
        window.location.hash = '';
      }, LOGIN_TIMEOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const renderComponent = () => {
    if (isLogout) {
      return <LogoutView />;
    } else {
      if (isAuthenticated) {
        if (localStorage['endUserSurveyPath'] && localStorage['endUserSurveyPath'] !== '') {
          return <div>Please Wait Loading Survey...</div>;
        } else {
          return (
            <ThemeProvider theme={themeSelected}>
              <CssBaseline />
              <RouterOutlet />
            </ThemeProvider>
          );
        }
      } else {
        return <div>Please Wait...</div>;
      }
    }
  };
  return renderComponent();
}

export default App;
